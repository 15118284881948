<template>
    <div ref="container">
        <highcharts ref="chart" :options="options"></highcharts>
    </div>
</template>


<script>

import { delay } from '@/utils/helper'
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'

exportingInit(Highcharts)

export default {
    name: 'ManpowerChart',
    components: {
        highcharts: Chart
    },
    data() {
        return {
            options: {
                chart: {},
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    labels: {
                        format: '{value} Mandays'
                    },
                    title: {
                        text: "Manday"
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.1f} Mandays</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: []
            }
        }
    },
    computed: {
        chart() {
            return this.$refs.chart
        },
        container() {
            return this.$refs.container
        }
    },
    methods: {
        updateChartData: function(data) {
            delay(() => {
                this.$set(this.options.title, 'text', data.title)
                this.$set(this.options.subtitle, 'text', data.subtitle)
                this.$set(this.options.xAxis, 'categories', data.categories)
                this.$set(this.options, "series", data.series)
                this.$set(this.options.chart, "width", this.container.clientWidth)
            }, 500)
        }
    }
}

</script>