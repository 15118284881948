<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Manpower Time Report Management</p>
                <p class="text-title mb-0">You do not have permission to view all manpower time's reports</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Manday Deployment</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-select dense multiple clearable hide-details label="Years" class="my-3" :items="years" :menu-props="{top: false, offsetY: true}" :rules="rule.year" v-model="filters.year"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-select dense clearable hide-details label="Months" class="my-3" :items="months" :menu-props="{top: false, offsetY: true}" v-model="filters.month"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" order="last">
                                <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click='search'>
                                    <v-icon small left>mdi-magnify</v-icon> View
                                </v-btn>
                                <v-btn small class="mt-2 elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="loading">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-layout align-center justify-center :style="getStyle()">
                        <v-progress-circular indeterminate :width="3" :size="60" color="primary"></v-progress-circular>
                    </v-layout>
                </v-col>
            </v-row>
            <v-row v-show="!loading">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <manpower-chart ref="chart"></manpower-chart>
                </v-col>
            </v-row>
            <v-row v-show="!loading">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <manpower-table :is-downloadable="permissions.can_download" ref="table"></manpower-table>
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>

import { copyObject, delay } from '@/utils/helper'
import { stringifyJSON } from '@/utils/encodes'
import { monthSelectList, makeYearList } from '@/utils/dates'
import ManpowerChart from './ManpowerChart.vue'
import ManpowerTable from './ManpowerTable.vue'


export default {
    name: 'ManpowerPanel',
    components: {
        manpowerChart: ManpowerChart,
        manpowerTable: ManpowerTable,
    },
    data() {
        return {
            loading: true,
            filters: {
                years: [new Date().getFullYear().toString()],
                month: '',
            },
            object: {},
            permissions: {
                can_list: false,
                can_download: false
            },
            rule: {
                year: [
                    (value) => {
                        if(value && value.length > 5) {
                            return 'Only accept up to 5 years'
                        }

                        return true
                    }
                ]
            }
        }
    },
    computed: {
        chart() {
            return this.$refs.chart
        },
        table() {
            return this.$refs.table
        },
        years() {
            return makeYearList(2021)
        },
        months() {
            return monthSelectList()
        },
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.loading = true
            var filters = copyObject(this.filters)
            filters.years = stringifyJSON(this.filters.years)
            this.$store.dispatch('epanel/report/getReportManpower', filters).then((response) => {
                this.object = copyObject(this.filters)
                this.permissions = response.data.permissions
                delay(() => {
                    this.loading = false
                    this.chart.updateChartData(response.data.chart)
                    this.table.updateTableData({ items: response.data.items, object: this.object })
                }, 1500)
            }).catch(() => {
                this.loading = false
            })
        },
        search: function() {
            this.get()
        },
        reset: function() {
            this.filters = { years: [new Date().getFullYear().toString()], month: '' }
        },
        getStyle: function() {
            return {"min-height": "200px"}
        }
    }
}

</script>