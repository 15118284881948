<template>
    <div>
        <v-row v-if="isDownloadable">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-btn small color="primary" class="elevation-0 mb-2" :ripple="false" @click="openDownloadDialog">Download Report</v-btn>
            </v-col>
        </v-row>
        <v-simple-table v-if="items.length > 0">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center" v-text="getTableHeader()"></th>
                        <th class="text-center" :key="index" v-text="item" v-for="(item, index) in object.years"></th>
                        <th class="text-center">Average Mandays</th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="index" v-for="(item, index) in items">
                        <td class="text-center" v-text="item.name"></td>
                        <td class="text-center" :key="sindex" v-for="(sitem, sindex) in item.values" v-text="sitem"></td>
                        <td class="text-center" v-text="item.average_value"></td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <download-dialog ref="downloadDialog" @downloaded="openLoadingDialog"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
    </div>
</template>


<script>

import ManpowerDownloadDialog from './ManpowerDownloadDialog.vue'
import SimpleActionDialog from '../../../shared/SimpleActionDialog.vue'

export default {
    name: 'ManpowerTable',
    components: {
        simpleActionDialog: SimpleActionDialog,
        downloadDialog: ManpowerDownloadDialog,
    },
    props: {
        isDownloadable: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            items: [],
            object: {
                years: []
            }
        }
    },
    computed: {
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        }
    },
    methods: {
        getTableHeader: function() {
            return this.items.length > 12?'Date / Year':'Month / Year'
        },
        updateTableData: function(data) {
            this.items = data.items
            this.object = data.object
        },
        openDownloadDialog: function() {
            if(this.isDownloadable){
                this.downloadDialog.updateObject(this.object)
                this.downloadDialog.open()
            }
        },
        openLoadingDialog: function(){
            this.loadingDialog.open()
        }
    }
}

</script>